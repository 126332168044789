<!--  心理咨询室  -->
<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        :tool-list="['college','keyword']"
        @on-search="renderTable(1)">
        <el-form-item label="状态" label-width="50px">
          <el-select v-model="queryInfo.offFlag" clearable size="small">
            <el-option :value="0" label="在岗" />
            <el-option :value="1" label="休假" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button">
        <!--     新增需求请求两个接口：    -->
        <el-button v-permission="['psycho:teacher:add']" type="primary" size="small" @click="showDialog()"
        >新增咨询师
        </el-button>
      </div>
    </template>
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column prop="name" label="名字" width="160" />
      <el-table-column prop="username" label="工号" width="200" />
      <el-table-column prop="post" label="职位" min-width="120" />
      <el-table-column prop="intro" label="简介" min-width="270" />
      <el-table-column label="状态" width="180" align="center">
        <template v-slot="{row}">
          <el-switch
            v-permission="['psycho:teacher:edit']"
            v-model="row.offFlag"
            @change="handleBatSwitch(row)"
            active-color="var(--color-success)"
            :active-value="0"
            active-text="在岗"
            inactive-text="休假"
            :inactive-value="1"
          />
          <template v-if="!isEditor">
            <el-tag v-if="row.offFlag" type="info">休假</el-tag>
            <el-tag v-else>在岗</el-tag>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220" align="center" fixed="right">
        <template v-slot="{row}">
          <el-button v-permission="['psycho:teacher:edit']" type="primary" plain size="mini"
                     @click="showDialog(row.id)">编辑
          </el-button>
          <el-button  type="primary" @click="showScheduleDialog(row.userId)">本周班表</el-button>
          <el-button v-permission="['psycho:teacher:del']"
                     size="mini"
                     type="danger"
                     @click="del(delPsychologyTeacherByIdApi, row.id,`心理咨询教师-${row.name}`)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <psychology-teacher-dialog ref="dialogRef"
                               :visible.sync="visibleDialog"
                               v-if="visibleDialog"
                               @on-close="renderTable" />
    <teacher-schedule-dialog ref="dialogScheduleRef"
                             :visible.sync="visibleScheduleDialog"
                             v-if="visibleScheduleDialog" />
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import {
  pagePsychologyTeacherApi,
  delPsychologyTeacherByIdApi,
  savePsychologyTeacherApi
} from '@/api/student-manage/psychology-teacher-api'
import PsychologyTeacherDialog
  from '@/views/pages/studentManage/psychology-teacher/components/psychologyTeacherDialog.vue'
import TeacherScheduleDialog from '@/views/pages/studentManage/psychology-teacher/components/teacherScheduleDialog.vue'

export default {
  name: 'psychologyTeacher',
  mixins: [tableView],
  components: {
    TeacherScheduleDialog,
    PsychologyTeacherDialog
  },
  data() {
    return {
      visibleScheduleDialog: false,
      isEditor: false, // 编辑权限
      queryInfo: {
        collegeId: null,
        deptId: null,
        offFlag: null,
        campusId: null
      },
      gradeYearList: []
    }
  },
  async mounted() {
    this.isEditor = this.$store.state.login.permissionTable.includes('psycho:teacher:edit')
    await this.renderTable(1)
  },
  methods: {
    delPsychologyTeacherByIdApi, // 删除api
    async renderTable(pageNum) {
      await this.getTableData(pagePsychologyTeacherApi, pageNum)
    },
    // 是否休假
    handleBatSwitch(row) {
      const msg = row.offFlag ? '休假' : '在岗'
      this.$confirm(`是否确认修改${row.name}的状态为${msg}?`).then(async () => {
        try {
          await savePsychologyTeacherApi(row)
          this.$message.success(`${row.name}已设为${msg}!`)
        } catch (e) {
        }
      }).catch(() => {
        row.offFlag = row.offFlag ? 0 : 1
        this.$message.info('已取消')
      })
    },
    showScheduleDialog(userId) {
      this.visibleScheduleDialog = true
      this.$nextTick(() => {
        this.$refs.dialogScheduleRef.parentInfo.userId = userId
        this.$refs.dialogScheduleRef.parentInfo.startDate = this.$moment().startOf('week').format('YYYY-MM-DD')
        this.$refs.dialogScheduleRef.parentInfo.endDate = this.$moment().endOf('week').format('YYYY-MM-DD')
      })
    }
  }
}
</script>
