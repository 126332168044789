<!--职位app-->
<template>
  <!--预设角色固定APP服务-->
  <el-dialog
    class="teacher-schedule-dialog"
    title="心理咨询师排班表"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="closed"
    width="720px"
  >
    <el-divider>排班表</el-divider>
    <div v-if="scheduleList.length" class="schedule-grid psycho">
      <div class="schedule-grid__header flex">
        <div class="schedule-grid__label" style="line-height: 48px;text-align: center;"><b>时间段</b></div>
        <div class="schedule-grid__label" v-for="(item,index) in scheduleList" :key="index">
          <b class="week-name">{{ item.week }}</b>
          <span class="week-date">{{ $moment(item.day).format('MM-DD') }}</span>
        </div>
      </div>
      <div class="schedule-grid__body flex-column">
        <div class="schedule-grid__wrap  flex schedule-grid__body-top">
          <div class="schedule-grid__column">
            <div class="schedule-grid__item time-stamp" v-for="(item,index) in scheduleList[0].list"
                 :key="index">
              <b>{{ item.classHourName }}</b>
            </div>
          </div>
          <div class="schedule-grid__column" v-for="(item,index) in scheduleList" :key="index">
            <div class="schedule-grid__item flex-column" v-for="(subItem,subIndex) in item.list" :key="subIndex">
              <template v-if="subItem.teacherInfoList&&subItem.teacherInfoList.length">
                <el-tag :type="subItem.campusName?getCampusType(subItem.campusName):''">{{ subItem.campusName }}</el-tag>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-empty v-else description="暂无排班" />
    <template #footer>
      <el-button @click="closed">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import dialog from '@/vue/mixins/dialog'
import { listPsychoScheduleApi } from '@/api/student-manage/psychology-schedule-api'

export default {
  name: 'teacherScheduleDialog',
  mixins: [dialog, tableView],
  props: {},
  data() {
    return {
      parentInfo: {
        userId: null, // 教师id
        startDate: null, // 周一
        endDate: null // 周日
      },
      teacherInfo: {},
      campusList: [], // 校区 带tag type
      formData: {
        id: '',
        name: '',
        intro: '',
        post: '',
        offFlag: 0,
        weekScheduleList: [],
        remarks: ''
      },
      scheduleList: []
    }
  },
  mounted() {
    this.getCampusList()
    this.$nextTick(async () => {
      await this.renderTable()
    })
  },
  methods: {
    async renderTable() {
      this.loading = true
      try {
        const res = await listPsychoScheduleApi(this.parentInfo)
        this.formData = res.data
        this.setSchedule(res.data)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    // 获取校区 list
    getCampusList() {
      const colors = ['primary', 'success', 'warning', 'danger', 'info']
      this.$http.getCampusListQuery().then((res) => {
        res.data.forEach((item, index) => {
          Object.assign(item, { type: colors[index] })
        })
        this.campusList = res.data
      })
    },
    // 获取校区的颜色标记
    getCampusType(name = null) {
      if (name) {
        return this.campusList.find(item => item.campusName === name).type || 'info'
      }
    },
    setSchedule(arr) {
      const _list = arr
      if (!_list.length) return
      const result = []
      const classHours = ['第一、二节', '第三、四节', '第五、六节', '第七、八节']
      for (let i = 0; i < 7; i++) {
        const _currentDay = this.$moment(this.parentInfo.startDate).add(i, 'days').format('YYYY-MM-DD')
        const _todaySchedule = _list.filter(item => item.day === _currentDay)
        const _todayTimeline = []
        // 一天4个排班时，有排班就插入，无就插入个空的占位
        classHours.forEach((hourName) => {
          const _course = _todaySchedule.find(item => item.classHourName === hourName)
          if (_course) {
            _todayTimeline.push(_course)
          } else {
            _todayTimeline.push({
              classHourName: hourName,
              day: _currentDay,
              id: hourName
            })
          }
        })
        // 把每天的排版按照日期去插入到result中
        result.push({
          week: '周' + this.$store.state.systemParam.weekList[this.$moment(_currentDay).days()],
          day: _currentDay,
          list: _todayTimeline
        })
      }
      this.scheduleList = result
    }
  }
}
</script>

<style lang="scss">
.teacher-schedule-dialog {
  .el-dialog {
    margin-top: 5vh !important;
  }

  .el-dialog__body {
    padding: 0 15px;

    .schedule-grid .schedule-grid__column .schedule-grid__item {
      position: relative;
      line-height: 50px;
      padding: 16px 0 10px 0;

      .day-flag {
        position: absolute;
        left: 0;
        top: 0;
        padding: 4px;
        height: auto;
        line-height: 1.2;
        border-radius: 0 0 4px 0;
      }
    }
  }
}
</style>
