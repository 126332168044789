<!--职位app-->
<template>
  <el-dialog
    class="post-dialog"
    title="心理咨询师职位"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="closed"
    width="720px"
  >
    <div class="mb-1" style="text-align: right">
      <el-button type="primary" size="small" @click="newRow">新增职位</el-button>
    </div>
    <el-form ref="formRef" :model="formData" v-loading="loading" :rules="rules">
      <el-table :data="formData.tableData" border>
        <el-table-column label="ID" type="index" width="50px" align="center" />
        <el-table-column label="职位名" width="160px">
          <template v-slot="scope">
            <el-form-item
              v-if="scope.row.isEditing"
              :prop="`tableData.${scope.$index}.paramName`"
              :rules="rules.paramName">
              <el-input v-model="scope.row.paramName" />
            </el-form-item>
            <span v-else>{{ scope.row.paramName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="职位值" min-width="60px">
          <template v-slot="scope">
            <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.paramValue`"
                          :rules="rules.paramValue">
              <el-input v-model="scope.row.paramValue" />
            </el-form-item>
            <span v-else>{{ scope.row.paramValue }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" width="220px">
          <template v-slot="scope">
            <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.remarks`" :rules="rules.remarks">
              <el-input v-model="scope.row.remarks" />
            </el-form-item>
            <span v-else>{{ scope.row.remarks }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160px">
          <template v-slot="scope">
            <template v-if="scope.row.isEditing">
              <el-form-item>
                <el-button size="small" @click="cancel(scope)">取消</el-button>
                <el-button type="primary" size="small"
                           v-if="scope.row.isEditing" @click="save(scope)">保存
                </el-button>
              </el-form-item>
            </template>
            <template v-else>
              <template v-if="!isEditing">
                <el-button size="mini"
                           type="danger"
                           @click="del(deleteParams, scope.row.id,`系统参数/字典-${scope.row.paramName}`)">
                  删除
                </el-button>
                <el-button size="mini"
                           type="primary" plain
                           @click="editRow(scope)">编辑
                </el-button>
              </template>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <template #footer>
      <el-button @click="closed">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import dialog from '@/vue/mixins/dialog'
import { paramsType } from '@/views/pages/system/paramsList/dialog/options'
import Vue from 'vue'
import { deleteParams, getSysDictionaryByKey, addParams } from '@/api/sysParams'

export default {
  name: 'PostDialog',
  mixins: [dialog, tableView],
  data() {
    return {
      paramsType,
      formData: {
        tableData: []
      },
      isEditing: false,
      rules: {
        paramName: [{
          required: true,
          message: '请输入职位名',
          trigger: 'blur'
        },
          this.$store.state.commonValidate.limit30WordsObj
        ],
        paramValue: [{
          required: true,
          message: '请输入职位值',
          trigger: 'blur'
        },
          this.$store.state.commonValidate.limit30WordsObj
        ]
      }
    }
  },
  mounted() {
    this.renderTable()
  },
  methods: {
    deleteParams, // 删除api
    editRow(scope) {
      this.isEditing = true
      scope.row.isEditing = true
      Vue.set(this.formData.tableData, scope.$index, scope.row)
    },
    async renderTable() {
      this.loading = true
      try {
        const res = await getSysDictionaryByKey('psychoPost')
        this.formData.tableData = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    newRow() {
      if (this.isEditing) {
        this.$message('请先保存已修改的数据，再进行新增操作！')
      } else {
        const _row = {
          paramValue: '',
          paramName: '',
          paramType: 2, // 禁止修改，因此不显示
          remarks: '',
          paramKey: 'psychoPost', // 禁止修改，因此不显示
          isEditing: true
        }
        this.formData.tableData.unshift(_row)
        this.isEditing = true
      }
    },
    async cancel(scope) {
      scope.row.isEditing = false
      this.isEditing = false
      Vue.set(this.formData.tableData, scope.$index, scope.row)
      await this.renderTable()
    },
    save(scope) {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.$confirm('点击确定保存心理咨询师职位。', '保存').then(async () => {
            try {
              await addParams(scope.row)
            } catch (e) {
            } finally {
              this.isEditing = false
              await this.renderTable()
            }
          }).catch()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.post-dialog {
  .el-dialog {
    margin-top: 5vh !important;
  }

  .el-dialog__body {
    padding: 0 15px;

    .el-form {
      padding-right: 0;
    }
  }
}
</style>
