export const paramsType = [
  {
    title: '系统参数',
    type: 1,
    tag: 'success'
  },
  {
    title: '系统字典',
    type: 2,
    tag: 'danger'
  }
]
