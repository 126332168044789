// 心理咨询室 排班表
import request from '@/service/request'

/* 心理咨询 排班，list 按条件 规则：获取本周周次，和学期，传到后台，后台根据条件查询出排班表
* @params {string} startDate 开始日期
* @params {string} endDate 开始日期
* */
export const listPsychoScheduleApi = (params = {}) => {
  return request.post('/stu/psychologyWorkSchedule/listByBindTeacher', params)
}

/* 心理咨询 排班，获取教师详情
* @params {string} id
*/
export const getPsychoScheduleByIdApi = (id) => {
  return request.post(`/stu/psychologyTermWork/get/${id}`)
}

/* 心理咨询 排班， 删除教师
* @params {string} id
* */
export const delPsychoScheduleByIdApi = (id) => {
  return request.post(`/stu/psychologyTermWork/del/${id}`)
}

/* 心理咨询 排班，新增
* @params
* */
export const generatePsychoScheduleApi = (params) => {
  return request.post('/stu/psychologyTermWork/generate', params)
}

/* 心理咨询 排班，编辑后保存
* @params
* */
export const savePsychoScheduleApi = (params) => {
  return request.post('/stu/psychologyWorkSchedule/save', params)
}

/* 心理咨询 排班，批量保存
* @params {Array} scheduleList
* */
export const batchSavePsychoScheduleApi = (params) => {
  return request.post('/stu/psychologyWorkSchedule/saveBatch', { scheduleList: params })
}

// 排班记录

/* 学期排班记录，分页
* @params
* */
export const pageScheduleRecordApi = (params) => {
  return request.post('/stu/psychologyTermWork/page', params)
}

/* 学期排班记录，根据id获取
* @params
* */
export const getScheduleRecordByIdApi = (id) => {
  return request.post(`/stu/psychologyTermWork/get/${id}`)
}

/* 学期排班记录，保存 主要用于记录显示和app端值班模板显示
* @params
* */
export const saveScheduleRecordByIdApi = (params) => {
  return request.post('/stu/psychologyTermWork/save', params)
}

/* 学期排班记录，根据id获取
* @params
* */
export const delScheduleRecordByIdApi = (id) => {
  return request.post(`/stu/psychologyTermWork/del/${id}`)
}
